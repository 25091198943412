@import "~normalize.css";

*,
*::before,
*::after {
    box-sizing: border-box;
}

$primaryColor: #ff4564;
$primaryColor1: #fad9df;
$whiteColor: #fff;
$blackColor: #323337;
$greyColor: #838ea4;
$greyColor-1: #6b6d6f;

* {
    margin: 0;
    padding: 0;
}

body {
    font-family: "Inter", sans-serif;
}

main {
    overflow: hidden;
}

p,
h1,
h2,
h3,
h4,
h5,
h6 {
    overflow-wrap: break-word;
    margin: 0;
    padding: 0;
}

a {
    text-decoration: none;
    color: $blackColor;
}
a:hover {
    color: transparent;
    color: $blackColor;
}
ul,
ol {
    margin: 0;
    padding: 0;
}

.button-submit {
    width: 100%;
    letter-spacing: 0.3px;
    color: $whiteColor;
    padding: 12px 0;
    border-radius: 10px;
    font-size: 16px;
    font-weight: 600;
    border: 0;
    background-color: $blackColor;
    transition: all ease-in-out 0.3s;
}

.button-submit:hover {
    background-color: $primaryColor;
}

.button-link {
    padding: 6px 18px;
    background-color: $blackColor;
    border-radius: 10px;
    font-weight: 600;
    font-size: medium;
    transition: all ease-in-out 0.3s;
    display: flex;
    align-items: center;
    color: $whiteColor;
    min-width: 120px;
}
.button-link:hover {
    color: $whiteColor;
    background-color: $primaryColor;
}
.button-link-large {
    padding: 10px 22px;
    background-color: $blackColor;
    border-radius: 10px;
    font-weight: 600;
    font-size: medium;
    transition: all ease-in-out 0.3s;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    color: $whiteColor;
    min-width: 170px;
}
.button-link-large:hover {
    color: $whiteColor;
    background-color: $primaryColor;
}
.button-link-large-replace {
    padding: 10px 22px;
    background-color: $primaryColor1;
    border-radius: 10px;
    font-weight: 600;
    font-size: medium;
    transition: all ease-in-out 0.3s;
    display: flex;
    align-items: center;
    justify-content: center;
    color: $blackColor;
    min-width: 170px;
}
.button-link-large-replace:hover {
    color: $whiteColor;
    background-color: $primaryColor;
}
.button-link-replace {
    padding: 6px 18px;
    background-color: $primaryColor;
    border-radius: 10px;
    font-weight: 600;
    font-size: medium;
    transition: all ease-in-out 0.3s;
    display: flex;
    align-items: center;
    justify-content: center;
    color: $whiteColor;
}
.button-link-replace:hover {
    color: $blackColor;
    background-color: $primaryColor;
}

.button-transparent {
    border: 1px solid $greyColor;
    padding: 8px 12px;
    border-radius: 10px;
    transition: all ease-in-out 0.3s;
}
.button-transparent:hover {
    color: $whiteColor;
    background-color: $blackColor;
}

.link-hover {
    color: #fff;
}
.link-hover:hover {
    color: $primaryColor;
}

.link-hover-tab {
    color: $greyColor;
    letter-spacing: 0.3px;
    font-size: 20px;
    font-weight: 600;
    padding-left: 10px;
}
.link-hover-tab.active {
    color: $blackColor;
    font-size: 20px;
    border-left: 2px solid $primaryColor;
}
.link-hover-tab:hover {
    color: #191a1c;
    border-left: 2px solid $primaryColor;
}

.text-hover {
    color: #191a1c;
}
.text-hover:hover {
    color: $greyColor;
}

.section-top {
    margin-top: 150px;
}
.section-bottom {
    margin-bottom: 100px;
}
.section-distance {
    margin: 100px 0 100px;
}

.section-top-big{
    margin-top: 36px;
}

@media screen and (min-width: 992px) {
    .section-top-big {
        margin-top: 214px;
    }
}

.custom-input {
    padding: 13px 15px;
    border-radius: 12px;
    border: 0;
    background-color: #f1f2f4cc;
    outline: none;
    width: 100%;
}

.clear-both {
    clear: both;
}

.box-dropdown {
    width: 100%;
    background-color: #f1f2f4;
    margin: 10px 0;
    color: #191a1c;
    margin: 20px 0;
    border: 0;
    border-radius: 10px;
    text-align: center;
}

//admin
.error {
    color: rgb(211, 29, 29);
    margin-left: 40px;
}

.text-large {
    font-size: 2.6rem;
    font-weight: 600;
    letter-spacing: 0.3px;
    line-height: 55px;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
}
.text-medium {
    font-size: 2.3rem;
    font-weight: 500;
    line-height: 55px;
}

.btn {
    padding: 0.4rem 0.8rem;
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 230px;
    margin: 0 auto;
}

.btn-primary {
    background-color: #e2132aeb;
    border-color: #e2132aeb;
}

.bg-hpay {
    background-color: #e2132aeb !important;
}
.hero-wrap {
    position: relative;
    height: 100vh;
}

.hero-mask {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-color: #e2132aeb;
    opacity: 0.8;
}
.hero-bg {
    z-index: 0;
    background-image: url("https://img.freepik.com/premium-photo/futuristic-robot-artificial-intelligence-concept_31965-6966.jpg");
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}
.hero-content {
    position: absolute;
    top: 0;
    left: 0;
}
.logo-login {
    margin-left: 5rem;
    margin-top: 5rem;
    img {
        height: 70px;
        width: auto;
    }
}

.btn-login-submit {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    background-color: #e2132aeb;
    border: 0;
    padding: 10px 80px;
    border-radius: 10px;
    color: #fff;
    font-size: 18px;
    font-weight: 500;
    transition: all 0.3s;
}
.btn-login-submit:hover {
    box-shadow: 0 5px 7px #9f9393;
}

.text-limit {
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
}
.text-label {
    font-weight: bold;
    letter-spacing: 0.3px;
    margin-bottom: 5px;
    color: #000;
}

.card-custom {
    position: relative;
    transition: all ease-in-out 0.5s;
    background-color: transparent;
}
.img-card {
    border-radius: 12px;
    z-index: 0;
    height: 370px;
    box-shadow: 0px 3px 12px rgba(0, 0, 0, 0.2);
}
.img-single {
    width: 100%;
    z-index: 0;
    box-shadow: 0px 3px 12px rgba(0, 0, 0, 0.2);
    border-radius: 12px;
}

.btn-card {
    position: absolute;
    left: 50%;
    transform: translate(-50%, -50%);
    bottom: 0;
    padding: 12px 26px;
    border-radius: 18px;
    background-color: #fff;
    opacity: 0;
    border: 0;
    visibility: hidden;
    transition: all ease 0.3s;
}

.card-custom:hover {
    .btn-card {
        bottom: 55%;
        opacity: 1;
        visibility: visible;
        z-index: 1;
        background-color: #fff;
        transition: all ease 0.5s;
    }
    .img-card {
        filter: blur(1.3px);
        z-index: 0;
    }
}

.title-1 {
    font-size: 44px;
    font-weight: 700;
    line-height: 56.7px;
    letter-spacing: 0.5px;
}
.title-2 {
    font-size: 18px;
    font-weight: 700;
    line-height: 22.4px;
    letter-spacing: 0.2px;
}
.desc-1 {
    font-size: 18px;
    font-weight: 400;
    line-height: 22.4px;
    letter-spacing: 0.2px;
    color: $greyColor-1;
    text-align: justify;
}
.desc-2 {
    font-size: 18px;
    font-weight: 400;
    line-height: 22.4px;
    letter-spacing: 0.2px;
    color: $blackColor;
    text-align: justify;
}
.desc-3 {
    font-size: 18px;
    font-weight: 400;
    line-height: 22.4px;
    letter-spacing: 0.2px;
    color: $whiteColor;
    text-align: justify;
}
