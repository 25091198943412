footer {
    background-color: #000;
    background-image: url("../../assets/icon/footer.png");
    background-repeat: no-repeat;
    background-position: right center;
    padding: 40px 0;
    width: 100%;
}
.title {
    font-size: 14px;
    font-weight: 600;
    margin-top: 30px;
    color: #6b6d6f;
}
.desc {
    margin-top: 25px;
    color: #fff;
    font-size: 13px;
    font-weight: 500;
    li > p {
        margin-top: 15px;
    }
}



