.banner-web {
    width: 100%;
    display: flex;
    height: 480px;
    border-radius: 50px 180px 50px 180px;
    .btn-content {
        width: 50%;
        padding: 68px 0 0 68px;
    }
    .btn-image {
        position: absolute;
        top: 126px;
        right: 300px;
        img{
            width: 32rem;
            height: auto;
        }
    }
}
