.cherry-blossom-container {
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100vw;
    overflow: hidden;
}

.petal {
    position: absolute;

    svg {
        width: 1.5rem;
        height: 1.5rem;

        /* path {
            fill: #FFB7C5;
        } */
    }

    &.animate-falling {
        animation-timing-function: linear;
        animation-iteration-count: 1;
    }

    &.animate-spin {
        animation-timing-function: linear;
        animation-iteration-count: infinite;
    }
}

@keyframes falling {
    0% {
        transform: translateY(-10%) rotate(0deg);
    }

    100% {
        transform: translateY(100vh) rotate(360deg);
    }
}

@keyframes spin {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}