.tutorial-wrapper-left {
    max-width: 300px;
    margin-top: 50px;
    position: relative;
}
.tutorial-wrapper {
    margin-top: 50px;
    max-width: 300px;
    position: relative;
}
.tutorial-icon {
    margin: 20px 0 20px 70px;
}

@mixin tutorial-color-main($background-color) {
    position: absolute;
    background-color: $background-color;
    bottom: 0;
    right: 0;
    width: 200px;
    height: 200px;
    border-radius: 50%;
    z-index: -1;
    filter: blur(50px);
    opacity: 0.5;
}

.tutorial-color[data-color="D7B1FF"] {
    @include tutorial-color-main(#d7b1ff);
}
.tutorial-color[data-color="71FFB6"] {
    @include tutorial-color-main(#71ffb6);
}
.tutorial-color[data-color="FFCC97"] {
    @include tutorial-color-main(#ffcc97);
}

@media screen and (min-width: "992px") {
    .tutorial-top {
        float: right;
        margin-top: -80px;
    }
    .tutorial-item-top {
        margin-top: -170px;
    }
}

@media screen and (min-width: "1200px") {
    .tutorials {
        margin-right: 100px;
    }
}
