@mixin about-box($height, $max-width, $border-radius, $gradient-color1, $gradient-color2, $padding) {
    min-height: $height;
    max-width: $max-width;
    border-radius: $border-radius;
    background-image: linear-gradient(to right, $gradient-color1, $gradient-color2);
    padding: $padding;
    overflow: hidden;
}

.about-box-left {
    @include about-box(278px, 500px, 30px 100px, #ffd88d, #c9ffc5, 40px);
}

.about-box-right {
    @include about-box(278px, 500px, 100px 30px, #aefeb5, #adf6f9, 40px);
}
