.header2 {
    padding: 3px;
    background-color: #f0f3f7;
}

.header {
    padding: 10px;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.08);
    transition: 0.5s ease;
    background-image: url("../../assets/images/header.png");
    background-repeat: no-repeat;
    background-size: cover;
}

header.sticky {
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
    background-color: #fffffff6;
}

header.sticky .header {
    padding: 5px;
}

.contact-content {
    color: #b4b5b7;
    font-size: 12px;

    a {
        color: #b4b5b7;
    }
}

.item-navbar {
    width: 120px;
    height: 30px;
    display: flex;
    justify-content: center;
    letter-spacing: 0.2px;
    color: #191a1c;
    font-weight: 600;
    font-size: 16px;
    border: 0;
    background: transparent;
    padding-bottom: 2px;
}

.item-navbar:hover {
    color: #191a1c;
    border-bottom: 2px solid #191a1c;
    outline: none;
}

.item-navbar.active {
    border-bottom: 2px solid #ff4564;
}

.accordion-button[aria-expanded="true"]::after {
    background-image: url("../../assets/icon/close.png");
    background-repeat: no-repeat;
    content: "";
    width: 20px;
    height: 20px;
    display: inline-block;
    margin-left: 20px;
}

.accordion-button[aria-expanded="false"]::after {
    background-image: url("../../assets/icon/open.png");
    background-repeat: no-repeat;
    content: "";
    width: 20px;
    height: 20px;
    display: inline-block;
    margin-left: 20px;
}

.accordion-button {
    display: flex;
    justify-content: space-between;
}

.dropdown-menu {
    display: none;
}

.dropdown:hover .dropdown-menu {
    display: block;
}

@media screen and (max-width: 992px) {
    .header2 {
        display: none;
    }
}