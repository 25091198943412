.section-home1 {
    margin-top: 100px;
}
.home2 {
    background-color: #000000;
    padding: 20px 0;
    background-image: url("../../assets/icon/footer.png");
    background-position: top 200px center;
    background-repeat: no-repeat;
}
.home-box {
    padding: 50px 20px;
    display: flex;
    justify-content: center;
    background-image: linear-gradient(to right, #ffefbd, #ebc1f5);
    border-radius: 30px;
}

.home-box-item {
    display: flex;
}

.wrapper-box {
    max-width: 650px;
}
.box-item {
    padding: 10px 20px;
    background-color: #fff;
    border-radius: 20px;
    margin-top: 25px;
    img {
        margin-right: 10px;
    }
}

.partner-item-img {
    width: 90px;
    height: 50px;
}
